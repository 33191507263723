

$(".js-open-video").modalVideo();
$('#book').turn({
    // fade: true,
    // speed: 600
    display: 'single'
})
$('.arrow-next').click(function(){
    $('#book').turn("next");
})
$('.arrow-prev').click(function(){
    $('#book').turn("previous");
})